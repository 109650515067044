import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import './assets/app.scss'
import axios from './axios';
import flashMessagePlugin from '@/plugins/flashMessage';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(fas);


const app = createApp(App)

app.component('font-awesome-icon', FontAwesomeIcon);

app.use(router)

app.use(flashMessagePlugin)

app.mount('#app')

app.config.globalProperties.$axios = axios;
