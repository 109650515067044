<template>
  <div v-if="chartData && chartData.datasets && chartData.datasets.length" class="small-chart">
    <PieChart :data="chartData" :options="chartOptions" />
  </div>
  <div v-else>
    <p>Žádná data k zobrazení.</p>
  </div>
</template>

<script>
import { Pie } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale, Decimation, Filler, SubTitle } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, Decimation, Filler, SubTitle);

export default {
  name: 'PriorityPieChart',
  components: {
    PieChart: Pie
  },
  props: {
    flats: {
      type: Array,
      required: true
    }
  },
  computed: {
    chartData() {
      if (!this.flats.length) {
        return {
          labels: [],
          datasets: []
        };
      }

      // Seskupení priorit 5-10 do jedné kategorie
      const groupedPriorities = this.flats.reduce((counts, flat) => {
        if (flat.priority === 1) {
          counts['Naprosto přesná shoda'] = (counts['Naprosto přesná shoda'] || 0) + 1;
        } else if (flat.priority === 2) {
          counts['Téměř přesná shoda'] = (counts['Téměř přesná shoda'] || 0) + 1;
        } else if (flat.priority === 3) {
          counts['Průměrná shoda'] = (counts['Průměrná shoda'] || 0) + 1;
        } else if (flat.priority === 4) {
          counts['Podprůměrná shoda'] = (counts['Podprůměrná shoda'] || 0) + 1;
        } else if (flat.priority >= 5 && flat.priority <= 10) {
          counts['Méně relativní shoda vhodná pro výpočet'] = (counts['Méně relativní shoda vhodná pro výpočet'] || 0) + 1;
        }
        return counts;
      }, {});

      const totalFlats = this.flats.length;

      // Převod na procenta
      const priorityPercentages = Object.values(groupedPriorities).map(count => (count / totalFlats) * 100);

      // Definice barev přímo přiřazením k jednotlivým kategoriím
      const backgroundColors = {
        'Naprosto přesná shoda': '#23ff00',
        'Téměř přesná shoda': '#007a06',
        'Průměrná shoda': '#e1bc01',
        'Podprůměrná shoda': '#c0974b',
        'Méně relativní shoda vhodná pro výpočet': '#603f00'
      };

      return {
        labels: Object.keys(groupedPriorities),
        datasets: [
          {
            backgroundColor: Object.keys(groupedPriorities).map(label => backgroundColors[label]),
            data: priorityPercentages,
          }
        ]
      };
    },



    chartOptions() {
      return {
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position: 'top'
          },
          labels:{
            display: false,
          },
          title: {
            display: false,
            text: 'Vyhledané nemovitosti'
          }
        }
      };
    }
  }
};
</script>
