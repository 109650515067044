<template>
  <transition name="fade">
    <div v-if="visible" :class="['flash-message', type]" class="fixed bg-sky50 bottom-4 right-4 p-4 rounded-lg shadow-lg flex items-center space-x-2">
      <span v-if="type === 'success'">
       <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21.856 10.303c.086.554.144 1.118.144 1.697 0 6.075-4.925 11-11 11s-11-4.925-11-11 4.925-11 11-11c2.347 0 4.518.741 6.304 1.993l-1.422 1.457c-1.408-.913-3.082-1.45-4.882-1.45-4.962 0-9 4.038-9 9s4.038 9 9 9c4.894 0 8.879-3.928 8.99-8.795l1.866-1.902zm-.952-8.136l-9.404 9.639-3.843-3.614-3.095 3.098 6.938 6.71 12.5-12.737-3.096-3.096z"/></svg>
      </span>
      <span v-if="type === 'error'" class="text-red-500">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.971 0h-9.942l-7.029 7.029v9.941l7.029 7.03h9.941l7.03-7.029v-9.942l-7.029-7.029zm-1.402 16.945l-3.554-3.521-3.518 3.568-1.418-1.418 3.507-3.566-3.586-3.472 1.418-1.417 3.581 3.458 3.539-3.583 1.431 1.431-3.535 3.568 3.566 3.522-1.431 1.43z"/></svg>
      </span>
      <p class="text-gray-800">
        {{ message }}
      </p>
      <button @click="close" class="text-gray-500 hover:text-gray-700">&times;</button>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      message: '',
      type: 'success',
    };
  },
  methods: {
    showMessage({ message, type }) {
      this.message = message;
      this.type = type;
      this.visible = true;
      setTimeout(this.close, 1750);
    },
    close() {
      this.visible = false;
    },
  },
};
</script>

<style scoped>
.flash-message {
  background-color: white;
  border: 1px solid #ddd;
  max-width: 300px;
  z-index: 1000;
}
.flash-message.success {
  border-left: 4px solid #22c55e;
}
.flash-message.error {
  border-left: 4px solid #d55a41;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
