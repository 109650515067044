<template>
  <div class="w-full p-4">
    <form @submit.prevent="validateAndCreateTag" class="space-y-4 bg-gray-50 p-6">
      <div>
        <label for="tag-name" class="block text-sm font-medium text-gray-700">Přidat nový štítek</label>
        <input
            id="tag-name"
            v-model="newTagName"
            type="text"
            placeholder="Název štítku"
            class="border rounded p-2 w-full"
            required
        />
        <p v-if="nameError" class="text-red-500 text-sm mt-1">{{ nameError }}</p>
      </div>
      <div class="flex items-center">
        <div class="w-4/5">
          <label class="block text-sm font-medium text-gray-700 mb-2">Vyberte barvu:</label>
          <div class="flex space-x-2">
            <div
                v-for="color in availableColors"
                :key="color"
                :style="{ backgroundColor: color }"
                @click="newTagColor = color"
                class="w-10 h-10 rounded-full cursor-pointer border-2 transition-all duration-200 ease-in-out transform hover:scale-110"
                :class="{ 'border-blue-500': newTagColor === color }"
            ></div>
          </div>
        </div>
        <div class="ml-4 mt-6 w-1/5 flex justify-end">
          <button
              type="submit"
              class="bg-sky-500 text-white text-sm font-medium px-5 py-2 rounded-md shadow hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-200 ease-in-out transform hover:scale-105"
          >
            Přidat
          </button>
        </div>
      </div>
    </form>
    <div class="mt-8 px-6">
      <h2 class="text-lg font-semibold mb-4">Štítky</h2>
      <div class="flex flex-wrap gap-4">
        <template v-if="tags">
          <span
              v-for="tag in tags"
              :key="tag.id"
              :style="{ backgroundColor: tag.color }"
              class="px-4 py-2 text-sm font-semibold text-white rounded-full flex items-center space-x-2 shadow-lg"
          >
            <span>{{ tag.name }}</span>
            <button @click="openModalTag(tag.id)" class="text-white hover:text-red-500">
              &times;
            </button>
          </span>
        </template>
      </div>
    </div>
    <delete-confirm
        @close="closeModalTag"
        :is-visible="isDeleteModalOpen"
        @confirm="deleteTag"
        message="Opravdu si přejete smazat tento štítek?"
    />
  </div>
</template>

<script>
import DeleteConfirm from "@/components/share/DeleteConfirm.vue";

export default {
  name: "TagsList",
  components: { DeleteConfirm },
  data() {
    return {
      tags: [],
      newTagName: "",
      newTagColor: "#07c6f6",
      nameError: null,
      isDeleteModalOpen: false,
      idForDelete: null,
      availableColors: ["#07c6f6", "#feac00", "#d55a41", "#7584ee", "#7f972a"],
    };
  },
  mounted() {
    this.fetchTags();
  },
  methods: {
    async fetchTags() {
      try {
        const response = await this.$axios.get("/tags");
        this.tags = response.data;
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    },
    validateAndCreateTag() {
      if (this.newTagName.length < 2) {
        this.nameError = "Název štítku musí obsahovat alespoň 2 znaky.";
      } else if (this.newTagName.length > 20) {
        this.nameError = "Název štítku nesmí obsahovat více než 20 znaků.";
      } else {
        this.nameError = null;
        this.createTag();
      }
    },
    async createTag() {
      try {
        const response = await this.$axios.post("/tags", {
          name: this.newTagName,
          color: this.newTagColor,
        });
        this.tags.push(response.data);
        this.$flashMessage.success("Štítek byl úspěšně přidán");
        this.newTagName = "";
        this.newTagColor = "#07c6f6";
      } catch (error) {
        console.error("Error creating tag:", error);
        this.$flashMessage.error("Štítek se nepodařilo přidat");
      }
    },
    openModalTag(id) {
      this.idForDelete = id;
      this.isDeleteModalOpen = true;
    },
    closeModalTag() {
      this.idForDelete = null;
      this.isDeleteModalOpen = false;
    },
    async deleteTag() {
      try {
        await this.$axios.delete(`/tags/${this.idForDelete}`);
        await this.closeModalTag();
        await this.fetchTags();
        this.$flashMessage.success("Štítek byl úspěšně odstraněn");
      } catch (error) {
        console.error("Error deleting tag:", error);
      }
    },
  },
};
</script>

<style scoped>
/* Styl pro animaci a přechody */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
