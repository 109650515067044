<template>
  <div>
    <h1 class="text-3xl">Nastavení předplatného</h1>

    <button
        class="flex sm:inline-flex items-center justify-center text-white bg-sky-500 hover:bg-sky-700 font-medium text-sm px-3 py-3"

        @click="redirectToCustomerPortal">Nastavení předplatného</button>

    <!-- Zde můžeš zpracovat další logiku -->
  </div>
</template>

<script>
export default {
  name: 'SettingSubscription',

  methods:{
    async redirectToCustomerPortal() {
      try {
        const response = await this.$axios.post('/create-customer-portal-session');
        window.location.href = response.data.portalUrl;
      } catch (error) {
        console.error('Error redirecting to customer portal:', error);
      }
    }
  }
}
</script>
