<template>
  <div>
    <h1>Děkujeme za objednávku, Vaše předplatné je nyní aktivní!</h1>
    <p>Your session ID is: {{ sessionId }}</p>
    <!-- Zde můžeš zpracovat další logiku -->
  </div>
</template>

<script>
export default {
  name: 'PaidSubscription',
  props: {
    sessionId: {
      type: String,
      required: false
    }
  },
  mounted() {
    if (this.sessionId) {
      console.log('Session ID:', this.sessionId);
    }
  }
}
</script>
