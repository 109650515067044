<template>
  <div class="space-y-8">
    <!-- Kupní cena nemovitosti -->
    <div class="w-full mt-3">
      <h3 class="text-xl pl-2 font-semibold text-gray-800 mb-1">
        <font-awesome-icon icon="sack-dollar" size="xs"/>
        Základní údaje o nemovitosti
      </h3>
      <div class="bg-gray-100 p-4 rounded-lg space-y-4">
        <div>
          <label class="block text-gray-700 font-semibold">Kupní cena nemovitosti (Kč):</label>
          <div class="relative">
            <input
                v-model="formattedPurchasePrice"
                @input="updatePurchasePrice"
                type="text"
                placeholder="Např. 3 000 000"
                class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200"
            >
            <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">Kč</span>
          </div>
          <p class="text-sm text-gray-500">Zadejte kupní cenu nemovitosti.</p>
        </div>

        <!-- Pořizovací náklady -->
        <div>
          <label class="block text-gray-700 font-semibold">Ostatní náklady spojené s pořízením (Kč):</label>
          <div class="relative">
            <input
                v-model="formattedLotSize"
                @input="updateLotSize"
                type="text"
                placeholder="Např. 200 000"
                class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200"
            >
            <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">Kč</span>
          </div>
          <p class="text-sm text-gray-500">Zadejte další náklady spojené s pořízením nemovitosti.</p>
        </div>
      </div>
    </div>

    <!-- Náklady na rekonstrukci -->
    <div class="w-full">
      <div class="flex items-center mt-5 mb-2">
        <h3 class="text-xl pl-2 font-semibold text-gray-800 mr-5">
          <font-awesome-icon icon="trowel-bricks" size="xs"/>
          Náklady na rekonstrukci
        </h3>
        <button
            @click="toggleRehabCosts"
            type="button"
            class="bg-sky-700 hover:bg-sky-800 text-xs flex content-center text-white px-2 py-1 rounded-md"
        >
          {{ showRehabCosts ? 'Nerozepisovat náklady' : 'Rozepsat náklady' }}
        </button>
      </div>
      <div v-if="showRehabCosts" class="bg-gray-100 p-4 rounded-lg space-y-4">
        <div class="space-y-4">
          <div v-for="(item, index) in rehabCosts" :key="index" class="flex space-x-4 items-center">
            <input
                v-model="item.name"
                type="text"
                placeholder="Název nákladu"
                class="block w-2/3 border border-gray-300 rounded-md p-2 focus:ring focus:ring-blue-200"
            >
            <div class="relative w-1/3">
              <input
                  :value="formatCurrency(item.amount)"
                  @input="(event) => updateRehabCost(index, event.target.value)"
                  type="text"
                  placeholder="Např. 50 000"
                  class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200"
              >
              <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">Kč</span>
            </div>
            <button
                @click="removeRehabCost(index)"
                type="button"
                class="text-red-500 hover:text-red-700"
            >
              <font-awesome-icon icon="xmark" size="xs"/>
            </button>
          </div>
        </div>
        <button
            @click="addRehabCost"
            type="button"
            class="bg-sky-700 text-sm flex content-center text-white px-4 py-2 rounded-md mt-2"
        >
          <font-awesome-icon icon="plus" size="xs" />
        </button>
      </div>
      <div v-else>
        <div class="bg-gray-100 p-4 rounded-lg space-y-4">
          <div class="relative">
            <label class="block text-gray-700 font-semibold">Celkové náklady na rekonstrukci:</label>
            <div class="relative">
              <input
                  v-model="rehabCostSingle"
                  @input="updateRehabCostSingle"
                  type="text"
                  placeholder="Např. 100 000"
                  class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200"
              >
              <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">Kč</span>
            </div>
          </div>
        </div>
      </div>
    </div>


      <div v-for="(item, index) in financingDetails" :key="index" class="w-full">

        <div class="flex items-center">
          <h3 class="text-xl pl-2 font-semibold text-gray-800 mr-5" v-if="index === 0 || isFinancingAllow">
            <font-awesome-icon icon="piggy-bank" size="xs"/>
            {{ item.name }}
          </h3>

          <label class="inline-flex items-center cursor-pointer mr-3" v-if="index === 0">
            <input type="checkbox" v-model="isFinancingAllow" class="sr-only peer" checked>
            <div class="relative w-11 h-6 bg-gray-200 rounded-full peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-sky-600"></div>
          </label>

          <button
              v-if="index === 0 && isFinancingAllow"
              @click="addFinancingDetail"
              type="button"
              class="bg-sky-700 hover:bg-sky-800 text-xs flex content-center text-white px-2 py-1 rounded-md"
          >
            Přidat financování
          </button>

          <button
              v-else-if="isFinancingAllow"
              @click="removeFinancingDetail(index)"
              type="button"
              class="text-red-500 hover:text-red-700"
          >
            <font-awesome-icon icon="xmark" size="xs"/>
          </button>
        </div>

      <template v-if="isFinancingAllow">
      <div  class="bg-gray-100 p-4 rounded-lg space-y-4 mt-4">
        <div class="space-y-4">
          <div class="flex space-x-4 items-center w-full" v-if="index !== 0">
            <label class="block text-gray-700 font-semibold w-full">Název financování:
              <input
                  v-model="item.name"
                  type="text"
                  placeholder="Název financování"
                  class="mt-1 block font-normal w-full border border-gray-300 rounded-md p-2 focus:ring focus:ring-blue-200">
            </label>
          </div>

          <div class="grid grid-cols-2 gap-4">
            <!-- Celková částka hypotéky -->
            <div>
              <label class="block text-gray-700 font-semibold">Cena nemovitosti pro banku (Kč):</label>
              <div class="relative">
                <input
                    :value="formatCurrency(item.amount)"
                    @input="(event) => updateLoanAmount(index, event.target.value)"
                    type="text"
                    placeholder="Např. 2 000 000"
                    class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200"
                >
                <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">Kč</span>
              </div>
              <p class="text-sm text-gray-500">Zadejte celkovou částku hypotéky.</p>
            </div>

            <!-- Podíl vlastní investice -->
            <div>
              <label class="block text-gray-700 font-semibold">Podíl vlastní investice (%):</label>
              <div class="relative">
                <input
                    v-model="item.investment_percentage"
                    type="number"
                    placeholder="Např. 20"
                    class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200"
                >
                <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">%</span>
              </div>
              <p class="text-sm text-gray-500">Zadejte podíl vlastní investice v procentech.</p>
            </div>
          </div>

          <div class="grid grid-cols-2 gap-4">
            <!-- Doba splácení -->
            <div>
              <label class="block text-gray-700 font-semibold">Doba splácení (roky):</label>
              <div class="relative">
                <input
                    v-model="item.loan_term"
                    type="number"
                    placeholder="Např. 20"
                    class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200"
                >
                <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">Roky</span>
              </div>
              <p class="text-sm text-gray-500">Zadejte dobu splácení v letech.</p>
            </div>

            <!-- Úroková sazba -->
            <div>
              <label class="block text-gray-700 font-semibold">Úroková sazba (%):</label>
              <div class="relative">
                <input
                    v-model="item.interest_rate"
                    type="number"
                    placeholder="Např. 2.5"
                    class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200"
                >
                <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">%</span>
              </div>
              <p class="text-sm text-gray-500">Zadejte úrokovou sazbu v procentech.</p>
            </div>
          </div>

          <!-- Měsíční pojištění úvěru -->
          <div>
            <label class="block text-gray-700 font-semibold">Měsíční pojištění úvěru (Kč):</label>
            <div class="relative">
              <input
                  :value="formatCurrency(item.monthly_insurance)"
                  @input="(event) => updateInsurance(index, event.target.value)"
                  type="text"
                  placeholder="Např. 2 000 000"
                  class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200"
              >
              <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">Kč</span>
            </div>
            <p class="text-sm text-gray-500">Zadejte částku měsíčního pojištění úvěru.</p>
          </div>
        </div>
      </div>
      </template>
      </div>
    </div>
</template>

<script>
export default {
  name: "PurchaseAndRehab",

  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },

  created() {
    if(this.form.repair_costs){
      this.rehabCosts = this.form.repair_costs;
    }
    if(this.form.financing_details){
      this.financingDetails = this.form.financing_details;
    }
  },

  watch: {
    rehabCosts: {
      handler(newVal) {
        this.form.repair_costs = newVal;
      },
      deep: true
    },
    financingDetails: {
      handler(newVal) {
        this.form.financing = newVal;
      },
      deep: true
    }
  },

  data() {
    return {
      showRehabCosts: false,
      isFinancingAllow: true,
      rehabCosts: [
        { name: 'Náklady na rekonstrukci', amount: null }
      ],
      financingDetails: [
        {
          name: 'Financování',
          amount: 0,
          investment_percentage: 0,
          loan_term: 0,
          interest_rate: 0,
          monthly_insurance: 0
        }
      ]
    };
  },

  computed: {
    form: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    },
    formattedPurchasePrice() {
      return this.form.purchase_price != null ? this.form.purchase_price.toLocaleString('cs-CZ') : '';
    },
    formattedAfterRepairValue() {
      return this.form.after_repair_value != null ? this.form.after_repair_value.toLocaleString('cs-CZ') : '';
    },
    formattedLotSize() {
      return this.form.lot_size != null ? this.form.lot_size.toLocaleString('cs-CZ') : '';
    },
    rehabCostSingle() {
      return this.rehabCosts[0].amount != null ? this.rehabCosts[0].amount.toLocaleString('cs-CZ') : '';
    }
  },
  methods: {
    toggleRehabCosts() {
      this.showRehabCosts = !this.showRehabCosts;
    },
    addRehabCost() {
      this.rehabCosts.push({name: '', amount: null});
    },

    removeRehabCost(index) {
      if(this.rehabCosts.length > 1){
        this.rehabCosts.splice(index, 1);
      }
    },

    updateRehabCostSingle(event) {
      const value = event.target.value.replace(/\s/g, '');
      this.rehabCosts[0].amount = parseInt(value, 10) || 0;
    },

    addFinancingDetail() {
      this.financingDetails.push({
        name: 'Další možnost financování',
        amount: 0,
        investment_percentage: 0,
        loan_term: 0,
        interest_rate: 0,
        monthly_insurance: 0
      });
    },
    removeFinancingDetail(index) {
      this.financingDetails.splice(index, 1);
    },
    updatePurchasePrice(event) {
      const value = event.target.value.replace(/\s/g, '');
      this.form.purchase_price = parseInt(value, 10) || 0;
    },
    updateAfterRepairValue(event) {
      const value = event.target.value.replace(/\s/g, '');
      this.form.after_repair_value = parseInt(value, 10) || 0;
    },
    updateLotSize(event) {
      const value = event.target.value.replace(/\s/g, '');
      this.form.lot_size = parseInt(value, 10) || 0;
    },
    formatCurrency(value) {
      return value != null ? value.toLocaleString('cs-CZ') : '';
    },
    updateRehabCost(index, value) {
      const numericValue = parseInt(value.replace(/\s/g, ''), 10) || 0;
      this.rehabCosts[index].amount = numericValue;
    },
    updateLoanAmount(index, value) {
      const numericValue = parseInt(value.replace(/\s/g, ''), 10) || 0;
      this.financingDetails[index].amount = numericValue;
    },
    updateInsurance(index, value) {
      const numericValue = parseInt(value.replace(/\s/g, ''), 10) || 0;
      this.financingDetails[index].monthly_insurance = numericValue;
    },
  }
};
</script>
