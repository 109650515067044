<template>
  <div class="relative single-property bg-white rounded-lg shadow-md flex justify-between items-center transition-all duration-200 ease-in-out hover:shadow-lg mt-4">
    <!-- Image Section -->
    <div class="relative w-1/3 overflow-hidden bg-gray-100">
      <img v-if="property.image_url" :src="'https://rilo.b-cdn.net/' + property.image_url" alt="Property Image" class="w-full h-auto max-h-48 object-cover">
      <img v-else src="https://rilo.b-cdn.net/static/blank-img.png" alt="Property Image" class="w-full h-auto max-h-48 object-cover">

      <div class="absolute top-2 left-2 flex flex-col space-y-1">
        <div class="flex items-center">
          <button @click.prevent="toggleTagPopup" class="focus:outline-none z-10 mr-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="scale-50">
              <g clip-path="url(#clip0_1_3)">
                <path d="M10.773 21.585L9.405 23L-1 12.571V4H1V11.719L10.773 21.585ZM12.772 1H3V10.772L15.074 23L25 13.15L12.772 1ZM8 8C6.895 8 6 7.105 6 6C6 4.895 6.895 4 8 4C9.105 4 10 4.895 10 6C10 7.105 9.105 8 8 8Z" fill="#000"/>
              </g>
              <defs>
                <clipPath id="clip0_1_3">
                  <rect width="24" height="24" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </button>
          <span v-for="tag in propertyTags" :key="tag" class="bg-orange-500 text-white text-xs px-2 py-1 rounded-lg shadow">
            {{ tag }}
          </span>
        </div>
      </div>
    </div>

    <!-- Property Data -->
    <div class="flex-1 flex flex-col px-4">
      <div class="flex justify-between items-center pb-2 border-b border-gray-200">
        <div class="flex justify-between space-x-4 w-full pt-4">
          <div class="flex-1 text-center">
            <span class="text-lg font-semibold text-green-600 block leading-none">250 000 Kč</span>
            <span class="text-xs text-gray-400">Cash Flow (m)</span>
          </div>
          <div class="flex-1 text-center">
            <span class="text-lg font-semibold text-gray-600 block leading-none">13.5 %</span>
            <span class="text-xs text-gray-400">Zhodnocení (r)</span>
          </div>
          <div class="flex-1 text-center">
            <span class="text-lg font-semibold text-gray-600 block leading-none">10 %</span>
            <span class="text-xs text-gray-400">ROI (r)</span>
          </div>
        </div>
        <!-- Dropdown Menu for Actions -->
        <div class="absolute right-0 top-0">
          <button @click="toggleDropdown" class="focus:outline-none w-8 h-8 bg-gray-50 rounded-bl-lg p-1">
            <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m22 16.75c0-.414-.336-.75-.75-.75h-18.5c-.414 0-.75.336-.75.75s.336.75.75.75h18.5c.414 0 .75-.336.75-.75zm0-5c0-.414-.336-.75-.75-.75h-18.5c-.414 0-.75.336-.75.75s.336.75.75.75h18.5c.414 0 .75-.336.75-.75zm0-5c0-.414-.336-.75-.75-.75h-18.5c-.414 0-.75.336-.75.75s.336.75.75.75h18.5c.414 0 .75-.336.75-.75z" fill-rule="nonzero"/></svg>          </button>
          <!-- Dropdown content -->
          <div v-if="dropdownOpen" class="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg z-50">
            <ul>
              <li class="mb-2">
                <router-link :to="'/edit-plan/' + property.id" class="w-full text-left text-sm text-gray-700 hover:text-blue-500">Upravit</router-link>
              </li>
              <li class="mb-2">
                <button class="w-full text-left text-sm text-gray-700 hover:text-gray-500">Nastavení</button>
              </li>
              <li>
                <button @click="openModalDeleteProperty()" class="w-full text-left text-sm text-gray-700 hover:text-red-500">Odstranit</button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Property Details -->
      <div class="mt-2 pt-2 pb-4 pl-5">
        <h3 class="text-lg font-semibold text-gray-800">{{ property.name }} ({{ property.disposition }} / {{ property.square_meters }} m<sup>2</sup>)</h3>
        <p class="text-sm text-gray-500 mt-1"> {{ property.address }}</p>
        <h4 class="text-xl font-bold text-gray-800 mt-2">{{ property.purchase_price }} Kč</h4>
      </div>
    </div>

    <!-- Price and Arrow -->
    <div class="absolute bottom-4 right-4 rounded-full bg-white p-2 shadow">
      <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" class="text-gray-500 hover:text-gray-700 transition-colors">
        <path d="M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z"/>
      </svg>
    </div>

    <!-- Popup for Tags -->
    <div v-if="isTagPopupOpen" ref="tagPopup" class="absolute left-0 top-6 mt-2 w-48 bg-white border rounded-lg shadow-lg z-50 p-4">
      <h3 class="text-lg font-bold mb-2">Přiřadit štítky</h3>
      <ul>
        <li v-for="tag in allTags" :key="tag.id" class="flex items-center justify-between mb-2">
          <span :style="{ backgroundColor: tag.color }" class="px-2 py-1 text-sm text-white rounded">
            {{ tag.name }}
          </span>
          <input type="checkbox" class="ml-1" :checked="propertyTags.includes(tag.id)" @change="toggleTag(tag.id)" />
        </li>
      </ul>
      <div class="flex justify-end">
        <button @click="toggleTagPopup" class="mt-4 px-4 py-2 bg-sky-500 text-xs text-white rounded">Zavřít</button>
      </div>
    </div>
  </div>
  <DeleteConfirm
      :is-visible="isModalDeleteOpen"
      @close="closeModalDeleteProperty"
      @confirm="confirmDelete"
  >

  </DeleteConfirm>
</template>

<script>
import DeleteConfirm from "@/components/share/DeleteConfirm.vue";

export default {
  name: "SingleProperty",
  components: {DeleteConfirm},
  props: {
    property: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      propertyTags: [],
      allTags: [],
      dropdownOpen: false,
      isTagPopupOpen: false,
      isModalDeleteOpen: false,
    };
  },
  mounted() {
    this.fetchPropertyTags();
  },
  methods: {
    async toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    async toggleTagPopup() {
      this.isTagPopupOpen = !this.isTagPopupOpen;
      if (this.isTagPopupOpen) {
        this.fetchTags();  // Load all tags when the popup is opened
      }
    },
    async fetchTags() {
      try {
        const response = await this.$axios.get('/tags');
        this.allTags = response.data;
      } catch (error) {
        console.error('Error fetching tags:', error);
      }
    },
    async fetchPropertyTags() {
      try {
        const response = await this.$axios.get(`/properties/${this.property.id}/tags`);
        this.propertyTags = response.data.map(tag => tag.name);  // Assuming the API returns an array of tag objects
      } catch (error) {
        console.error('Error fetching property tags:', error);
      }
    },
    async toggleTag(tagId) {
      if (this.propertyTags.includes(tagId)) {
        await this.detachTag(tagId);
      } else {
        await this.attachTag(tagId);
      }
    },
    async attachTag(tagId) {
      try {
        await this.$axios.post('/tags/attach', {
          property_id: this.property.id,
          tag_id: tagId,
        });
        this.propertyTags.push(tagId);
      } catch (error) {
        console.error('Error attaching tag:', error);
      }
    },
    async detachTag(tagId) {
      try {
        await this.$axios.post('/tags/detach', {
          property_id: this.property.id,
          tag_id: tagId,
        });
        this.propertyTags = this.propertyTags.filter(id => id !== tagId);
      } catch (error) {
        console.error('Error detaching tag:', error);
      }
    },

    openModalDeleteProperty(){
      this.isModalDeleteOpen = true;
    },

    closeModalDeleteProperty(){
      this.isModalDeleteOpen = false;
    },

    confirmDelete(){
      this.$emit('delete', this.property.id);
      this.isModalDeleteOpen = false;
    }

  }
};
</script>

<style scoped>
.single-property {
  background-color: #ffffff; /* White background */
}

.single-property:hover {
  background-color: #f8fafc; /* Slightly darker on hover */
}
</style>
