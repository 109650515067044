<template>
  <div class="rent-range">
    <div class="rent-labels">
      <span>{{ minRent }} Kč</span>
      <span>{{ averageRent }} Kč</span>
      <span>{{ maxRent }} Kč</span>
    </div>
    <div class="rent-gradient"></div>
  </div>
</template>

<script>
export default {
  name: 'RentRange',
  props: {
    minRent: {
      type: Number,
      required: true
    },
    maxRent: {
      type: Number,
      required: true
    },
    averageRent: {
      type: Number,
      required: true
    }
  }
};
</script>

<style scoped>
.rent-range {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 20px 0;
}

.rent-labels {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.rent-gradient {
  height: 20px;
  background: linear-gradient(to right, #603f00, #23ff00);
  border-radius: 5px;
}
</style>
