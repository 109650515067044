<template>
  <div class="mx-auto p-6 pt-12 bg-gray-100 min-h-screen rounded-lg">
    <h2 class="text-3xl font-extrabold mb-6 text-gray-800">Plánované nemovitosti</h2>
    <div class="flex flex-col md:flex-row">
      <!-- Kategorií -->
      <div class="w-full md:w-1/5 mb-6 md:mb-0 md:mr-6">
        <div class="md:block flex overflow-x-auto scrollbar-hidden  sticky top-0">
          <CategoryItem
              v-for="(item, index) in categories"
              :name="item.name"
              :image="item.image"
              :type="item.type"
              @switch="setActive"
              :is-active="isActive(item.type)"
              :key="index"
              class="cursor-pointer hover:scale-105 transition-transform"
          />
        </div>
      </div>

      <div class="w-full md:w-4/5 bg-white p-6 rounded-lg shadow-lg mt-2">
        <!-- Tlačítko "Přidat nemovitost" -->
        <div class="flex justify-end space-x-4">
          <button @click="openTagsModal" type="button" class="sm:inline-flex mb-3 hidden lg:visible items-center justify-center text-white bg-green-500 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-3 py-2">
            <svg class="mr-2 w-4 h-4 invert" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10.605 0h-10.604v10.609l13.39 13.391 10.609-10.605-13.395-13.395zm-7.019 6.414c-.781-.782-.781-2.047 0-2.828.782-.781 2.048-.781 2.828-.002.782.783.782 2.048 0 2.83-.781.781-2.046.781-2.828 0zm6.823 8.947l-4.243-4.242.708-.708 4.243 4.243-.708.707zm4.949.707l-7.07-7.071.707-.707 7.071 7.071-.708.707zm2.121-2.121l-7.071-7.071.707-.707 7.071 7.071-.707.707z"/></svg>            Štítky
          </button>
          <button @click="$emit('open-sign-modal')" type="button" class="sm:inline-flex mb-3 hidden lg:visible items-center justify-center text-white bg-sky-500 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-3 py-2">
            <svg aria-hidden="true" class="mr-1 -ml-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
            Přidat nemovitost
          </button>
        </div>

        <!-- Vyhledávání a Seřazení -->
        <div class="flex justify-between flex-col md:flex-row items-center mb-6">
          <form class="w-full flex">
            <button id="dropdown-button" data-dropdown-toggle="dropdown" class="flex-shrink-0 z-10 inline-flex items-center py-2 px-4 text-sm font-medium text-gray-900 bg-gray-200 border border-gray-300 rounded-l-lg hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-gray-200 transition-all" type="button">
              Seřadit <svg class="w-2.5 h-2.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4 4-4"/>
            </svg>
            </button>
            <div id="dropdown" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 ">
              <ul class="py-2 text-sm text-gray-700 " aria-labelledby="dropdown-button">
                <li>
                  <button type="button" class="inline-flex w-full px-4 py-2 hover:bg-gray-100 ">Město</button>
                </li>
                <li>
                  <button type="button" class="inline-flex w-full px-4 py-2 hover:bg-gray-100 ">Cena</button>
                </li>
                <li>
                  <button type="button" class="inline-flex w-full px-4 py-2 hover:bg-gray-100 ">Výnosnost</button>
                </li>
                <li>
                  <button type="button" class="inline-flex w-full px-4 py-2 hover:bg-gray-100 ">Potřeba kapitálu</button>
                </li>
              </ul>
            </div>
            <div class="relative w-full">
              <input type="search" id="search-dropdown" class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-100 rounded-r-lg border-l-gray-100 border-l-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500" placeholder="Vyhledat nemovitost..." required />
              <button type="submit" class="absolute top-0 right-0 p-2.5 text-sm font-medium h-full text-white bg-sky-800 rounded-r-lg border border-blue-600 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300">
                <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                </svg>
                <span class="sr-only">Search</span>
              </button>
            </div>
          </form>
        </div>

        <!-- Hlavní obsah -->
        <div class="lg:p-6 rounded-lg bg-gray-50 shadow-sm">
          <SingleProperty
              v-for="property in filteredProperties"
              :key="property.id"
              :property="property"
              @delete="deleteProperty"
          />
        </div>
      </div>
    </div>
    <ModalApp
        :is-open="isTagsModalOpen"
        @close="closeTagsModal"
        title="Správce štítků"
        size="medium"
    >
      <TagsList />
    </ModalApp>
  </div>
</template>

<script>
import CategoryItem from "@/components/planned/CategoryItem.vue";
import SingleProperty from "@/components/planned/SingleProperty.vue";
import TagsList from "@/components/planned/TagsList.vue";
import ModalApp from "@/components/ModalApp.vue";
export default {
  name: "PlannedProperties",

  emits: ['openSignModal'],

  data() {
    return {
      categories: [
        {
          name: 'Pronájem',
          image: 'rent.png',
          type: 'rent',
        },
        {
          name: 'Flipování',
          image: 'flip.png',
          type: 'flip',
        },
        {
          name: 'BRRR strategie',
          image: 'brrr.png',
          type: 'brrr',
        },
        {
          name: 'Hold',
          image: 'invest.png',
          type: 'hold'
        }
      ],
      activeCategory: 'rent',
      properties: [],
      isTagsModalOpen: false,
      isLoading: false,
      error: null,
    }
  },

  components: {
    ModalApp,
    CategoryItem, SingleProperty, TagsList
  },

  computed: {
    filteredProperties() {
      return this.properties.filter(property => property.category === this.activeCategory);
    }
  },

  mounted() {
    this.fetchProperties();
  },

  methods: {
    isActive(type) {
      return this.activeCategory === type;
    },

    setActive(type) {
      this.activeCategory = type;
    },

    async deleteProperty(id){
      try {
        await this.$axios.delete(`/planned-properties/${id}`, {'user_id': 5});
        this.$flashMessage.success("Nemovitost byla úspěšně odstraněna");
        await this.fetchProperties();
      } catch (error) {
        console.error("Error deleting tag:", error);
      }
    },

    closeTagsModal() {
      this.isTagsModalOpen = false;
    },
    openTagsModal() {
      this.isTagsModalOpen = true;
    },
    async fetchProperties() {
      this.isLoading = true;
      this.error = null;

      try {
        const response = await this.$axios.get('/planned-properties');
        this.properties = response.data.reduce((acc, category) => {
          return acc.concat(category.properties);
        }, []);
      } catch (error) {
        console.error('Error fetching properties:', error);
        this.error = 'Nepodařilo se načíst data.';
      } finally {
        this.isLoading = false;
      }
    }
  }
}
</script>

<style scoped>
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Add some transition for hover effects */
.cursor-pointer {
  transition: transform 0.2s ease-in-out;
}

/* Example card shadow for property listing */
.shadow-lg {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.shadow-sm {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}
</style>
