<template>
  <section class="bg-gray-50">
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <a href="#" class="flex items-center mb-6 text-2xl font-semibold text-gray-900">
        Rilo.cz
      </a>
      <div class="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
            Přihlásit se
          </h1>
          <form @submit.prevent="loginUser" class="space-y-4 md:space-y-6">
            <div>
              <label for="email" class="block mb-2 text-sm font-medium text-gray-900">
                E-mail
              </label>
              <input
                  v-model="data.email"
                  type="email"
                  name="email"
                  id="email"
                  class="bg-gray-50 border border-gray-300 text-gray-900
                  rounded-lg focus:ring-primary-600 focus:border-primary-600
                  block w-full p-2.5"
                  placeholder="email@email.cz"
                  required="">
            </div>
            <div>
              <label for="password" class="block mb-2 text-sm font-medium text-gray-900 ">
                Heslo
              </label>
              <input
                  v-model="data.password"
                  type="password"
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  required="">
            </div>
            <div class="flex items-center justify-between">
              <div class="flex items-start">
                <div class="flex items-center h-5">
                  <input id="remember" aria-describedby="remember" type="checkbox" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300" required="">
                </div>
                <div class="ml-3 text-sm">
                  <label for="remember" class="text-gray-500">Zapamatujte si mě</label>
                </div>
              </div>
              <a href="#" class="text-sm font-medium text-primary-600 hover:underline">Zapomenuté heslo?</a>
            </div>
            <button type="submit" class="w-full text-white bg-sky-500 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Přihlásit se</button>
            <p class="text-sm font-light text-gray-500">
              Ještě nemáte účet? <router-link :to="{name: 'RegisterPage'}" class="font-medium text-primary-600  hover:underline">Vytvořte si ho zde</router-link>
            </p>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Cookies from 'js-cookie';

export default {
  name: "LoginPage",

  data() {
    return {
      data: {
        email: '',
        password: '',
      }
    };
  },
  methods: {
    loginUser() {
      axios.post(`${process.env.VUE_APP_API_ADDRESS}api/login`, this.data)
          .then(response => {
            console.log('Přihlášení bylo úspěšné:', response.data);
            Cookies.set('authToken', response.data.token, {expires: 7});

            console.log('Posílám zprávu:', response.data.token);
            window.postMessage({
              type: 'SET_AUTH_TOKEN',
              token: response.data.token
            }, '*');
          })
          .catch(error => {
            console.error('Chyba při přihlášení:', error);
          });
    }
  },
};
</script>

<style scoped>
/* Optional custom styles */
</style>
