<template>
  <div>
    <h1>Vaše předplatné nebylo zaplaceno :(</h1>
    <!-- Zde můžeš zpracovat další logiku -->
  </div>
</template>

<script>
export default {
  name: 'PaidSubscription',
}
</script>
