<template>
  <div class="min-h-screen bg-gray-100 flex items-center justify-center p-6">
    <div class="bg-white shadow-lg rounded-lg overflow-hidden flex flex-col md:flex-row">
      <!-- Left side (Image) -->
      <div class="md:w-1/2">
        <img
            src="https://via.placeholder.com/600x400"
            alt="Subscription Image"
            class="w-full h-full object-cover"
        >
      </div>
      <!-- Right side (Content) -->
      <div class="md:w-1/2 p-6">
        <h1 class="text-3xl font-bold mb-4">Předplatné </h1>
        <p class="text-gray-700 mb-4">
          Získejte neomezený přístup k našemu prémiovému obsahu a exkluzivním funkcím.
        </p>
        <ul class="list-disc list-inside mb-6">
          <li>Neomezený přístup k prémiovým článkům</li>
          <li>Exkluzivní newsletter</li>
          <li>Slevy na produkty</li>
          <li>Přednostní podpora</li>
        </ul>
        <button
            @click="startSubscription"
            class="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 transition">
          Koupit Předplatné
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
export default {
  name: "PurchaseSubscription",
  data() {
    return {
      data: 'value',
    }
  },
  methods: {
    async startSubscription() {
      try {
        const response = await this.$axios.post('/create-checkout-session');

        window.location.href = response.data.checkoutUrl;
      } catch (error) {
        console.error('Chyba při vytváření předplatného:', error);
      }
    },
    getToken() {
      return Cookies.get('authToken');
    }
  }
}
</script>
