<template>
  <div class="image-upload">
    <div v-if="uploading" class="relative flex items-center justify-center w-full h-48">
      <svg class="animate-spin h-12 w-12 text-blue-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.75 22c0 .966-.783 1.75-1.75 1.75s-1.75-.784-1.75-1.75.783-1.75 1.75-1.75 1.75.784 1.75 1.75zm-1.75-22c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2zm10 10.75c.689 0 1.249.561 1.249 1.25 0 .69-.56 1.25-1.249 1.25-.69 0-1.249-.559-1.249-1.25 0-.689.559-1.25 1.249-1.25zm-22 1.25c0 1.105.896 2 2 2s2-.895 2-2c0-1.104-.896-2-2-2s-2 .896-2 2zm19-8c.551 0 1 .449 1 1 0 .553-.449 1.002-1 1-.551 0-1-.447-1-.998 0-.553.449-1.002 1-1.002zm0 13.5c.828 0 1.5.672 1.5 1.5s-.672 1.501-1.502 1.5c-.826 0-1.498-.671-1.498-1.499 0-.829.672-1.501 1.5-1.501zm-14-14.5c1.104 0 2 .896 2 2s-.896 2-2.001 2c-1.103 0-1.999-.895-1.999-2s.896-2 2-2zm0 14c1.104 0 2 .896 2 2s-.896 2-2.001 2c-1.103 0-1.999-.895-1.999-2s.896-2 2-2z"/></svg>
    </div>
    <div v-else-if="imageUrl" class="relative">
      <img :src="'https://rilo.b-cdn.net/' + imageUrl" alt="Nahraný obrázek" class="w-full h-48 object-cover rounded-md">
      <button @click="remove" class="absolute top-2 right-2 bg-red-600 text-white w-6 h-6 flex justify-center content-center rounded-full">×</button>
    </div>
    <div
        v-else
        class="flex items-center justify-center w-full h-48 border-2 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 transition-colors"
        @dragover.prevent
        @drop.prevent="handleDrop"
        @click="openFileDialog"
    >
      <input
          type="file"
          ref="fileInput"
          @change="handleFileUpload"
          accept="image/*"
          class="hidden"
      />
      <div class="text-center">
        <svg class="mx-auto h-12 w-12 text-blue-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19.479 10.092c-.212-3.951-3.473-7.092-7.479-7.092-4.005 0-7.267 3.141-7.479 7.092-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408zm-7.479-1.092l4 4h-3v4h-2v-4h-3l4-4z"/></svg>

        <p class="mt-2 text-gray-600">
          Přetáhněte fotografii sem nebo nahrajte manuálně.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imageUrl: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      uploading: false, // stav pro nahrávání
    };
  },
  methods: {
    openFileDialog() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.uploadFile(file);
      }
    },
    handleDrop(event) {
      const file = event.dataTransfer.files[0];
      if (file) {
        this.uploadFile(file);
      }
    },
    async uploadFile(file) {
      this.uploading = true;
      const formData = new FormData();
      formData.append('image', file);

      try {
        const response = await this.$axios.post('/properties/images', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.status === 201) {
          this.$emit('uploaded', '' + response.data.image.file_path);
          console.log('Image uploaded successfully:', response.data.image.file_path);
        } else {
          console.error('Error uploading image:', response.statusText);
        }
      } catch (error) {
        console.error('Error uploading image:', error);
      } finally {
        this.uploading = false;
      }
    },
    remove() {
      this.$emit('removed');
    }
  }
}
</script>

<style scoped>
/* Styl pro náhled obrázku */
.image-upload img {
  display: block;
}
</style>
