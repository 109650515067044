<template>
  <div class="flex flex-col sm:flex-row justify-center items-center gap-4 p-4">
    <!-- Box pro přidání -->
    <div @click.prevent="pushTo('add')" class="flex flex-col items-center justify-center w-full sm:w-1/2 bg-white hover:bg-gray-100 border border-gray-300 rounded-lg p-6 shadow transition duration-300 ease-in-out">
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"><path d="M5 21h-5v-2h5v2zm3.424-5.718l4.402 4.399-5.826 1.319 1.424-5.718zm15.576-6.748l-9.689 9.804-4.536-4.536 9.689-9.802 4.536 4.534z"/></svg>
      <span class="mt-2 text-xl font-semibold text-center text-gray-800">Přidat nemovitost ručně</span>
    </div>

    <!-- Box pro import -->
    <div @click.prevent="pushTo('import')" class="flex flex-col items-center justify-center w-full sm:w-1/2 bg-white hover:bg-gray-100 border border-gray-300 rounded-lg p-6 shadow transition duration-300 ease-in-out">
      <svg width="48" height="48" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M17 16h-4v8h-2v-8h-4l5-6 5 6zm7 8h-9v-2h7v-16h-20v16h7v2h-9v-24h24v24z"/></svg>
      <span class="mt-2 text-xl font-semibold text-center text-gray-800">Importovat nemovitost z sreality.cz</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignPost",

  methods:{
    pushTo(name){
      if(name === 'add'){
        this.$router.push({name: 'AddProperties'})
        this.$emit('close');
      }else if(name === 'import'){
        this.$router.push({name: 'ImportProperties'})
        this.$emit('close');
      }
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
/* Případně můžete přidat další styly pro jemné doladění vzhledu */
</style>
