<template>
  <MenuTemplate @openSignModal="signPostOpen"/>
  <FlashMessage id="flash-message" />
  <div class="bg-gray-100">
    <div class="max-w-screen-xl m-auto">
      <router-view v-slot="{ Component, route }" @openSignModal="signPostOpen">
        <transition
            :name="route.meta.transition || 'fade'"
            mode="out-in"
        >
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
  </div>

  <ModalApp
      :is-open="signpostModal"
      size="medium"
      title="Přidat nemovitost"
      @close="signpostModal = false"
  >
    <SignPost @close="signpostModal = false"/>
  </ModalApp>
</template>

<script>
import MenuTemplate from "@/components/MenuTemplate.vue";
import ModalApp from "@/components/ModalApp.vue";
import SignPost from "@/components/share/SignPost.vue";
import FlashMessage from "@/components/share/FlashMessage.vue";

export default {
  name: "AppPage",

  components: {
    SignPost,
    FlashMessage,
    MenuTemplate,
    ModalApp
  },

  data() {
    return {
      signpostModal: false
    };
  },

  methods: {
    signPostOpen() {
      this.signpostModal = true;
    },
    notify(type, title, message) {
      this.$refs.notification.showNotification({ type, title, message });
    }
  }
};
</script>

<style>
/* Animace pro přechod z PlannProperties do AddProperties nebo EditProperties */
.slide-left-enter-active {
  animation: slideInFromRight 0.3s ease-out forwards;
}
.slide-left-leave-active {
  animation: slideOutToLeft 0.3s ease-in forwards;
}

/* Animace pro přechod z AddProperties nebo EditProperties do PlannProperties */
.slide-right-enter-active {
  animation: slideInFromLeft 0.3s ease-out forwards;
}
.slide-right-leave-active {
  animation: slideOutToRight 0.3s ease-in forwards;
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOutToLeft {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%);
    opacity: 0;
  }
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOutToRight {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(50%);
    opacity: 0;
  }
}

/* Fade pro ostatní přechody */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
