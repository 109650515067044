<template>
  <section class="bg-gray-50">
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto  min-h-screen lg:py-0">
      <a href="#" class="flex items-center mb-6 text-2xl font-semibold text-gray-900">
        <img class="w-8 h-8 mr-2" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/logo.svg" alt="logo">
        Rilo.cz
      </a>
      <div class="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
            Vytvořte si účet
          </h1>
          <form
              @submit.prevent="registerUser"
              class="space-y-4 md:space-y-6"
          >
            <div>
              <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Uživatelské jméno</label>
              <input
                  v-model="data.name"
                  type="text"
                  name="name" id="name"
                  class="bg-gray-50 border border-gray-300 text-gray-900
                  text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600
                  block w-full p-2.5"
                  placeholder="Josef Novák"
                  required=""
              >
            </div>
            <div>
              <label for="email" class="block mb-2 text-sm font-medium text-gray-900">E-mail</label>
              <input
                  v-model="data.email"
                  type="email"
                  name="email" id="email"
                  class="bg-gray-50 border border-gray-300 text-gray-900
                  text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600
                  block w-full p-2.5"
                  placeholder="jmeno@domena.cz"
                  required=""
              >
            </div>
            <div>
              <label for="password" class="block mb-2 text-sm font-medium text-gray-900">Heslo</label>
              <input
                  v-model="data.password"
                  type="password"
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  class="bg-gray-50 border border-gray-300 text-gray-900
                  text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600
                  block w-full p-2.5"
                  required=""
              >
            </div>
            <div>
              <label for="confirm-password" class="block mb-2 text-sm font-medium text-gray-900">Heslo pro kontrolu</label>
              <input
                  v-model="data.password_confirmation"
                  type="password"
                  name="confirm-password"
                  id="confirm-password"
                  placeholder="••••••••"
                  class="bg-gray-50 border border-gray-300 text-gray-900
                  text-sm rounded-lg focus:ring-primary-600
                  focus:border-primary-600 block w-full p-2.5"
                  required=""
              >
            </div>
            <div class="flex items-start">
              <div class="flex items-center h-5">
                <input id="terms" aria-describedby="terms" type="checkbox" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300" required="">
              </div>
              <div class="ml-3 text-sm">
                <label for="terms" class="font-light text-gray-500"> Souhlasím s podmínkami <a class="font-medium text-primary-600 hover:underline" href="#"> užívání aplikace</a></label>
              </div>
            </div>
            <button type="submit" class="w-full text-white bg-sky-500 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
              Vytvořit účet
            </button>
            <p class="text-sm font-light text-gray-500">
              Již máte účet? <router-link to="/login" class="font-medium text-primary-600 hover:underline">Přihlaste se zde</router-link>
            </p>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "RegisterPage",

  data() {
    return {
      data:{
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
      }
    }
  },

  methods:{
    registerUser() {
      axios.post(`${process.env.VUE_APP_API_ADDRESS}api/register`, this.data)
          .then(response => {
            console.log('Registrace byla úspěšná:', response.data);
          })
          .catch(error => {
            console.error('Chyba při registraci:', error);
      });
    }
  }
}
</script>