import axios from 'axios';
import Cookies from 'js-cookie';

const instance = axios.create({
    baseURL: `${process.env.VUE_APP_API_ADDRESS}api`,
});

instance.interceptors.request.use(
    config => {
        const token = Cookies.get('authToken');

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export default instance;
