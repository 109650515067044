<template>
  <div
      @click.prevent="$emit('switch', type)"
      :class="[
      'flex mt-3 items-center p-4 bg-white shadow min-w-64 cursor-pointer hover:bg-blue-100 transition-colors duration-200',
      { 'border-l-4 border-gray-800': isActive }
    ]"
  >
    <img :src="`/images/icons/${image}`" alt="icon" class="w-8 h-8 mr-4"/>
    <span class="text-lg font-semibold text-gray-700">{{ name }}</span>
  </div>
</template>

<script>
export default {
  name: "CategoryItem",
  props: {
    name: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
/* Dodatečné úpravy stylů pokud potřeba */
</style>
