import { createRouter, createWebHistory } from 'vue-router';
import Cookies from 'js-cookie';
import Login from '@/pages/LoginPage.vue';
import Register from '@/pages/RegisterPage.vue';
import Application from '@/AppPage.vue';
import RentCalculator from '@/pages/Apps/RentCalculator.vue';
import PlannProperties from '@/pages/PlannProperties.vue';
import MyProperties from '@/pages/MyProperties.vue';
import AddProperties from '@/pages/AddProperties.vue';
import PurchaseSubscription from "@/pages/PurchaseSubscription.vue";
import PaidSubscription from "@/pages/Subscription/PaidSubscription.vue";
import CancelSubscription from "@/pages/Subscription/CancelSubscription.vue";
import SettingSubscription from "@/pages/Subscription/SettingSubscription.vue";

const routes = [
    {
        path: '/',
        name: 'AppPage',
        component: Application,
        children: [
            {
                path: 'rent-calculator',
                name: 'RentCalculator',
                component: RentCalculator,
                meta: { transition: 'fade' }
            },
            {
                path: 'planned',
                name: 'PlannProperties',
                component: PlannProperties,
                meta: { transition: 'slide-left' }
            },
            {
                path: 'add-plan',
                name: 'AddProperties',
                component: AddProperties,
                meta: { transition: 'slide-right' }
            },
            {
                path: 'edit-plan/:id',
                name: 'EditProperties',
                component: AddProperties,
                props: true,
                meta: { transition: 'slide-right' }
            },
            {
                path: 'import-plan',
                name: 'ImportProperties',
                component: AddProperties,
                props: { isImport: true },
                meta: { transition: 'slide-right' }
            },
            {
                path: 'my',
                name: 'MyProperties',
                component: MyProperties,
                meta: { transition: 'fade' }
            },
            {
                path: 'subscription',
                name: 'UserSubscription',
                component: PurchaseSubscription,
                meta: { transition: 'fade' }
            },
            {
                path: 'success',
                name: 'PaidSubscription',
                component: PaidSubscription,
                meta: { transition: 'fade' },
                props: route => ({ sessionId: route.query.session_id }),
            },
            {
                path: 'cancel',
                name: 'CancelSubscription',
                component: CancelSubscription,
                meta: { transition: 'fade' }
            },
            {
                path: 'setting/subscription',
                name: 'SettingSubscription',
                component: SettingSubscription,
                meta: { transition: 'fade' }
            },
        ],
    },
    {
        path: '/login',
        name: 'LoginPage',
        component: Login
    },
    {
        path: '/register',
        name: 'RegisterPage',
        component: Register
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    const token = Cookies.get('authToken');

    if (token && (to.path === '/login' || to.path === '/register')) {
        next('/');
    } else if (!token && to.path !== '/login' && to.path !== '/register') {
        next('/login');
    } else {
        if (from.name === 'PlannProperties' && (to.name === 'AddProperties' || to.name === 'EditProperties')) {
            to.meta.transition = 'slide-left';
        } else if ((from.name === 'AddProperties' || from.name === 'EditProperties') && to.name === 'PlannProperties') {
            to.meta.transition = 'slide-right';
        } else {
            to.meta.transition = 'fade';
        }
        next();
    }
});

export default router;
