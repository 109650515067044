<template>
  <div class="mx-auto p-6 pt-12 bg-gray-100 min-h-screen rounded-lg">
    <h2 class="text-3xl font-extrabold mb-6 text-gray-800">
      <font-awesome-icon icon="chevron-left" size="xs" class="mr-2 cursor-pointer hover:text-sky-500" @click="$router.go(-1)"/>
      <template v-if="!form.name">
         Nová nemovitost
      </template>
      <template v-else>
        {{form.name}}
      </template>
    </h2>
    <div class="flex flex-col md:flex-row">
      <!-- Kroková navigace -->
      <div class="w-full md:w-1/5 mb-6 md:mb-0 md:mr-6">
        <div class="md:block flex overflow-x-auto sticky top-0 scrollbar-hidden">
          <div
              v-for="(step, index) in steps"
              :key="index"
              @click="setActiveStep(index)"
              :class="[
              'flex mt-3 items-center p-4 bg-white shadow min-w-64 cursor-pointer hover:bg-blue-100 transition-colors duration-200',
              { 'border-l-4 border-sky-500': activeStep === index }
            ]"
          >
            <img :src="`/images/icons/forms/` + step.icon + '.svg'" alt="icon" class="w-8 h-8 mr-4"/>
            <span class="text-lg font-semibold text-gray-700">  {{ step.name }}</span>
          </div>
        </div>
      </div>

      <!-- Aktivní formulář -->
      <div class="w-full md:w-4/5 bg-white pb-10 rounded-tl-lg rounded-bl-lg shadow-lg mt-2 relative transition">
        <div class="flex justify-end w-full">
          <button
              @click.prevent="toggleAiModalOpen(true)"
              type="button"
              class="flex sm:inline-flex items-center justify-center text-white bg-sky-500 hover:bg-sky-700 font-medium text-sm px-3 py-3"
          >
            <font-awesome-icon icon="brain" class="mr-2"/>
            Vyplnit pomocí AI
          </button>

          <button
              @click="saveProperty"
              type="button"
              class="flex right-auto sm:inline-flex items-center justify-center text-white bg-green-600 hover:bg-green-700 font-medium text-sm px-3 py-3"
          >
            <svg class="mr-2 w-4 h-4 invert" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15.004 3h2.996v5h-2.996v-5zm8.996 1v20h-24v-24h20l4 4zm-19 5h14v-7h-14v7zm16 4h-18v9h18v-9zm-2 2h-14v1h14v-1zm0 2h-14v1h14v-1z"/></svg>
            Uložit nemovitost
          </button>
        </div>
        <div class="p-8">
          <transition name="fade" mode="out-in">
            <component :is="steps[activeStep].component" v-model="form" v-bind="activeStepProps"></component>
          </transition>
        </div>

        <div class="flex justify-end mt-5">
          <button
              v-if="activeStep < 4"
              @click="nextStep"
              type="button"
              class="flex absolute right-0 bottom-0 sm:inline-flex items-center justify-center text-white bg-sky-700 hover:bg-sky-800 focus:ring-4 focus:ring-sky-300 font-medium text-sm px-3 py-3"
          >
            Pokračovat na další krok

            <font-awesome-icon icon="chevron-right" class="ml-2"/>
          </button>
        </div>
      </div>
    </div>
    <ModalApp
        :is-open="isAiModalOpen"
        size="medium"
        title="Vyplňování pomocí AI"
        @close="toggleAiModalOpen(false)"
    >
      <AiFillWindow :data="form" @get-data="getAiData"/>
    </ModalApp>
  </div>
</template>

<script>
import PropertyDescription from "@/components/forms/PropertyDescription.vue";
import PurchaseAndRehab from "@/components/forms/PurchaseAndRehab.vue";
import RentAndCosts from "@/components/forms/RentAndCosts.vue";
import LongTermProjections from "@/components/forms/LongTermProjections.vue";
import PropertyTaxes from "@/components/forms/PropertyTaxes.vue";
import ModalApp from "@/components/ModalApp.vue";
import SignPost from "@/components/share/SignPost.vue";
import AiFillWindow from "@/components/share/AiFillWindow.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: "AddProperties",

  components: {
    FontAwesomeIcon,
    AiFillWindow,
    SignPost, ModalApp,
    PropertyDescription,
    PurchaseAndRehab,
    RentAndCosts,
    LongTermProjections,
    PropertyTaxes
  },

  computed:{
    activeStepProps() {
      if (this.steps[this.activeStep].component === 'PropertyDescription') {
        return { nameError: this.nameError };
      }
      return {};
    }
  },

  data() {
    return {
      activeStep: 0,
      isAiModalOpen: false,
      isLockScreenAi: false,
      steps: [
        { name: 'O nemovitosti', component: 'PropertyDescription', icon: 'first' },
        { name: 'Počáteční náklady', component: 'PurchaseAndRehab',icon: 'second' },
        { name: 'Příjmy a výdaje', component: 'RentAndCosts', icon: 'third' },
        { name: 'Budoucí vývoj', component: 'LongTermProjections', icon: 'fourth' },
        { name: 'Daně', component: 'PropertyTaxes', icon: 'fifth'},
      ],
      form: {
        category: 'rent',
        name: "",
        description: "",
        tags: "",
        street: "",
        city: "",
        state: "",
        postal_code: "",
        image_url: null,
        purchase_price: null,
        repair_costs: [
          { name: 'Náklady na rekonstrukci', amount: null }
        ],
        financing: [
          {
            name: 'Financování',
            loan_amount: null,
            amount: null,
            investment_percentage: null,
            loan_term: null,
            interest_rate: null,
            monthly_insurance: null
          }
        ],
        gross_rent: null,
        vacancy_rate: null,
        other_income: [
          { name: 'Další příjmy z nemovitosti', amount: 0 }
        ],
        operating_expenses: [
          { name: 'Provozní náklady na nemovitost', amount: 0 }
        ],
        property_appreciation: null,
        income_growth: null,
        expense_growth: null,
        selling_costs: null,
        property_tax_rate: null,
        depreciation_term: null,
        total_taxes_cost: null,
        square_meters: null,
        property_type: 'byt',
        parking_spaces_type: 'žádné',
        disposition: '1+kk',
      },
      isEditMode: false,
      propertyId: null,
      nameError: false,
      hasUnsavedChanges: false,
    };
  },

  beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges) {
      const answer = window.confirm('Máte neuložené změny. Opravdu chcete opustit tuto stránku?');
      if (answer) {
        next(); // Pokračuje na další stránku
      } else {
        next(false); // Zůstane na aktuální stránce
      }
    } else {
      next(); // Pokračuje na další stránku
    }
  },


  async created() {
    this.propertyId = this.$route.params.id;
    if (this.propertyId) {
      this.isEditMode = true;
      await this.loadPropertyData();
    }
  },



  methods: {
    startAiLoading(){
      this.isLockScreenAi = true;
      this.isAiModalOpen = false;
    },

    setActiveStep(index) {
      this.activeStep = index;
    },

    getAiData(updatedData) {
      this.isLockScreenAi = false;
      Object.keys(updatedData).forEach(key => {
        if (Object.prototype.hasOwnProperty.call(this.form, key)) {
          if (Array.isArray(this.form[key])) {
            this.form[key] = [...updatedData[key]];
          } else {
            this.form[key] = updatedData[key];
          }
        }
      });

      this.toggleAiModalOpen(false);
    },


    toggleAiModalOpen(condition){
      this.isAiModalOpen = condition;
    },

    resetNameError(){
      this.nameError = false;
    },

    nextStep(){
      this.activeStep = this.activeStep + 1;
    },

    validateName(){
      if(this.form.name.length < 4 || this.form.name.length > 100){
        this.nameError = true;
        return false;
      }
      this.nameError = false;
      return true;
    },

    async saveProperty() {
      if (!this.validateName()) {
        return;
      }

      try {
        let response;
        if (this.isEditMode) {
          response = await this.$axios.put(`/planned-properties/${this.propertyId}`, this.form);
          this.$flashMessage.success('Nemovitost byla úspěšně upravena.');
        } else {
          response = await this.$axios.post('/planned-properties', this.form);
          this.$flashMessage.success('Nemovitost byla úspěšně přidána.');
          this.resetForm();
        }
        console.log(response);
      } catch (error) {
        console.error('Error saving property:', error);
        this.$flashMessage.error('Chyba při ukládání nemovitosti.');
      }
    },

    async loadPropertyData() {
      try {
        const response = await this.$axios.get(`/planned-properties/${this.propertyId}`);
        this.form = response.data;
      } catch (error) {
        console.error('Error loading property data:', error);
        this.$flashMessage.error('Chyba při načítání údajů o nemovitosti.');
      }
    },

    resetForm() {
      this.form = {
        category: 'rent',
        name: "",
        description: "",
        tags: "",
        street: "",
        city: "",
        state: "",
        postal_code: "",
        image_url: null,
        purchase_price: 0,
        repair_costs: [
          { name: 'Náklady na rekonstrukci', amount: null }
        ],
        financing: [
          {
            name: 'Financování',
            loan_amount: 0,
            amount: null,
            investment_percentage: 0,
            loan_term: 0,
            interest_rate: 0,
            monthly_insurance: 0
          }
        ],
        gross_rent: 0,
        vacancy_rate: 0,
        other_income: [
          { name: 'Hrubý příjem z pronájmu', amount: 0 }
        ],
        operating_expenses: [
          { name: 'Provozní náklady na nemovitost', amount: 0 }
        ],
        property_appreciation: 0,
        income_growth: 0,
        expense_growth: 0,
        selling_costs: 0,
        property_tax_rate: 0,
        depreciation_term: 0,
        total_taxes_cost: 0,
      };
      this.nameError = false;
      this.hasUnsavedChanges = false;
    }
  },

  }
</script>

<style scoped>
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.cursor-pointer {
  transition: transform 0.2s ease-in-out;
}

/* Stín pro seznam kategorií */
.shadow-lg {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.shadow-sm {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

@keyframes write {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(150px);
  }
  100% {
    transform: translateX(0);
  }
}

</style>