<template>
  <div class="space-y-4">
    <h3 class="text-xl pl-2 font-semibold text-gray-800">Daně</h3>

    <label class="inline-flex items-center cursor-pointer p-2">
      <input type="checkbox" v-model="isLumpActive" class="sr-only peer" checked>
      <div class="relative w-11 h-6 bg-gray-200 rounded-full peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-sky-600"></div>
      <span class="ms-3 text-sm font-medium text-gray-900"> Paušální výdaje</span>
    </label>

    <div class="bg-gray-100 p-4 rounded-lg space-y-4">
      <div class="grid grid-cols-1 gap-4">
        <!-- Paušál -->
        <div v-if="isLumpActive">
          <label class="block text-gray-700 font-semibold">Paušál (%):</label>
          <div class="relative">
            <input
                v-model="form.property_tax_rate"
                type="number"
                placeholder="Např. 15"
                class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200">
            <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">%</span>
          </div>
          <p class="text-sm text-gray-500">Zadejte paušální daň v procentech.</p>
        </div>

        <!-- Doba odepisování -->
        <div v-if="!isLumpActive">
          <label class="block text-gray-700 font-semibold">Doba odepisování (roky):</label>
          <div class="relative">
            <input
                v-model="form.depreciation_term"
                type="number"
                placeholder="Např. 30"
                class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200">
            <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">Roky</span>
          </div>
          <p class="text-sm text-gray-500">Zadejte dobu odepisování v letech.</p>
        </div>
      </div>

      <!-- Celková cena k odepisování -->
      <div v-if="!isLumpActive">
        <label class="block text-gray-700 font-semibold">Celková cena k odepisování (Kč):</label>
        <div class="relative">
          <input
              :value="formatCurrency(form.total_taxes_cost)"
              @input="updateLandValue"
              type="text"
              placeholder="Např. 1 500 000"
              class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200">
          <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">Kč</span>
        </div>
        <p class="text-sm text-gray-500">Zadejte celkovou cenu k odepisování.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PropertyTaxes",
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  computed: {
    form: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    }
  },
  data() {
    return {
      isLumpActive: true
    }
  },
  methods: {
    formatCurrency(value) {
      return value != null ? value.toLocaleString('cs-CZ') : '';
    },
    updateLandValue(event) {
      const value = event.target.value.replace(/\s/g, '');
      this.form.total_taxes_cost = parseInt(value, 10) || 0;
    }
  }
}
</script>
