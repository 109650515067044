<template>
  <div class="space-y-4">
    <!-- Hrubý nájem -->
    <div class="flex gap-8">
      <div class="w-1/2">
        <label class="block text-gray-700 font-semibold">Hrubý nájem (Kč):</label>
        <div class="relative">
          <input
              :value="formatCurrency(form.gross_rent)"
              @input="updateGrossRent"
              type="text"
              placeholder="Např. 20 000"
              class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200">
          <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">Kč</span>
        </div>
        <p class="text-sm text-gray-500">Zadejte očekávaný hrubý měsíční nájem.</p>
      </div>

      <!-- Míra neobsazenosti -->
      <div class="w-1/2">
        <label class="block text-gray-700 font-semibold">Míra neobsazenosti (%):</label>
        <div class="relative">
          <input
              v-model="form.vacancy_rate"
              type="number"
              placeholder="Např. 5"
              class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200">
          <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">%</span>
        </div>
        <p class="text-sm text-gray-500">Zadejte očekávanou míru neobsazenosti v procentech.</p>
      </div>
    </div>

    <!-- Ostatní příjmy z nemovitosti -->
    <div class="w-full">
      <div class="flex items-center mt-5 mb-2">
        <h3 class="text-xl pl-2 font-semibold text-gray-800 mr-5">
          Ostatní příjmy z nemovitosti
        </h3>
        <button
            @click="toggleIncomes"
            type="button"
            class="bg-sky-700 hover:bg-sky-800 text-xs flex content-center text-white px-2 py-1 rounded-md"
        >
          {{ showIncomes ? 'Nerozepisovat příjmy' : 'Rozepsat příjmy' }}
        </button>
      </div>
      <div v-if="showIncomes" class="bg-gray-100 p-4 rounded-lg space-y-4">
        <div class="space-y-4">
          <div v-for="(item, index) in form.other_income" :key="index" class="flex space-x-4 items-center">
            <input
                v-model="item.name"
                type="text"
                placeholder="Název příjmu"
                class="block w-2/3 border border-gray-300 rounded-md p-2 focus:ring focus:ring-blue-200">
            <div class="relative w-1/3">
              <input
                  :value="formatCurrency(item.amount)"
                  @input="(event) => updateExpectedIncome(index, event.target.value)"
                  type="text"
                  placeholder="Např. 10 000"
                  class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200">
              <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">Kč</span>
            </div>
            <button
                v-if="index !== 0"
                @click="removeExpectedIncome(index)"
                type="button"
                class="text-red-500 hover:text-red-700">
              <font-awesome-icon icon="xmark" size="xs"/>
            </button>
          </div>
        </div>
        <button
            @click="addExpectedIncome"
            type="button"
            class="bg-sky-700 text-sm flex content-center text-white px-4 py-2 rounded-md mt-2"
        >
          <font-awesome-icon icon="plus" size="xs" />
        </button>
      </div>
      <div v-else>
        <div class="bg-gray-100 p-4 rounded-lg space-y-4">
          <div class="relative">
            <label class="block text-gray-700 font-semibold">Další příjmy z nemovitosti:</label>
            <div class="relative">
              <input
                  v-model="incomesSingle"
                  @input="updateIncomesSingle"
                  type="text"
                  placeholder="Např. 30 000"
                  class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200">
              <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">Kč</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Provozní náklady/měsíc -->
    <div class="w-full">
      <div class="flex items-center mt-5 mb-2">
        <h3 class="text-xl pl-2 font-semibold text-gray-800 mr-5">
          Provozní náklady/měsíc
        </h3>
        <button
            @click="toggleExpenses"
            type="button"
            class="bg-sky-700 hover:bg-sky-800 text-xs flex content-center text-white px-2 py-1 rounded-md"
        >
          {{ showExpenses ? 'Nerozepisovat náklady' : 'Rozepsat náklady' }}
        </button>
      </div>
      <div v-if="showExpenses" class="bg-gray-100 p-4 rounded-lg space-y-4">
        <div class="space-y-4">
          <div v-for="(item, index) in form.operating_expenses" :key="index" class="flex space-x-4 items-center">
            <input
                v-model="item.name"
                type="text"
                placeholder="Název nákladu"
                class="block w-2/3 border border-gray-300 rounded-md p-2 focus:ring focus:ring-blue-200">
            <div class="relative w-1/3">
              <input
                  :value="formatCurrency(item.amount)"
                  @input="(event) => updateOperatingExpense(index, event.target.value)"
                  type="text"
                  placeholder="Např. 5 000"
                  class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200">
              <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">Kč</span>
            </div>
            <button
                v-if="index !== 0"
                @click="removeOperatingExpense(index)"
                type="button"
                class="text-red-500 hover:text-red-700">
              <font-awesome-icon icon="xmark" size="xs"/>
            </button>
          </div>
        </div>
        <button
            @click="addOperatingExpense"
            type="button"
            class="bg-sky-700 text-sm flex content-center text-white px-4 py-2 rounded-md mt-2"
        >
          <font-awesome-icon icon="plus" size="xs" />
        </button>
      </div>
      <div v-else>
        <div class="bg-gray-100 p-4 rounded-lg space-y-4">
          <div class="relative">
            <label class="block text-gray-700 font-semibold">Celkové provozní náklady:</label>
            <div class="relative">
              <input
                  v-model="expensesSingle"
                  @input="updateExpensesSingle"
                  type="text"
                  placeholder="Např. 15 000"
                  class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200">
              <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">Kč</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RentAndCosts",

  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      showIncomes: false,
      showExpenses: false
    };
  },
  computed: {
    form: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    },
    incomesSingle() {
      return this.form.other_income[0]?.amount != null ? this.form.other_income[0].amount.toLocaleString('cs-CZ') : '';
    },
    expensesSingle() {
      return this.form.operating_expenses[0]?.amount != null ? this.form.operating_expenses[0].amount.toLocaleString('cs-CZ') : '';
    }
  },
  methods: {
    formatCurrency(value) {
      return value != null ? value.toLocaleString('cs-CZ') : '';
    },
    updateGrossRent(event) {
      const value = event.target.value.replace(/\s/g, '');
      this.form.gross_rent = parseInt(value, 10) || 0;
    },
    toggleIncomes() {
      this.showIncomes = !this.showIncomes;
    },
    addExpectedIncome() {
      this.form.other_income.push({name: '', amount: 0});
    },
    removeExpectedIncome(index) {
      this.form.other_income.splice(index, 1);
    },
    updateExpectedIncome(index, value) {
      const numericValue = parseInt(value.replace(/\s/g, ''), 10) || 0;
      this.form.other_income[index].amount = numericValue;
    },
    updateIncomesSingle(event) {
      const value = event.target.value.replace(/\s/g, '');
      this.form.other_income[0].amount = parseInt(value, 10) || 0;
    },
    toggleExpenses() {
      this.showExpenses = !this.showExpenses;
    },
    addOperatingExpense() {
      this.form.operating_expenses.push({name: '', amount: 0});
    },
    removeOperatingExpense(index) {
      this.form.operating_expenses.splice(index, 1);
    },
    updateOperatingExpense(index, value) {
      const numericValue = parseInt(value.replace(/\s/g, ''), 10) || 0;
      this.form.operating_expenses[index].amount = numericValue;
    },
    updateExpensesSingle(event) {
      const value = event.target.value.replace(/\s/g, '');
      this.form.operating_expenses[0].amount = parseInt(value, 10) || 0;
    }
  }
}
</script>
