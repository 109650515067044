import { createApp } from 'vue';
import FlashMessageComponent from "@/components/share/FlashMessage.vue"; // Importuj FlashMessage komponentu

export default {
    install(app) {
        const flashMessageApp = createApp(FlashMessageComponent);
        const instance = flashMessageApp.mount(document.createElement('div'));

        document.body.appendChild(instance.$el);

        app.config.globalProperties.$flashMessage = {
            show(message, type = 'success') {
                instance.showMessage({ message, type });
            },
            success(message) {
                instance.showMessage({ message, type: 'success' });
            },
            error(message) {
                instance.showMessage({ message, type: 'error' });
            }
        };
    }
};
